.Header-Containeer{
    width: 100%;
    margin-top: 150px;
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Header-Containeer .Image-Placeholder{
    position: relative;
    width: 80%;
    height: 300px;
    background-color: black;
}

.Header-Containeer .Image-Placeholder .Header-Overlay{
    box-sizing: border-box;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.35);
    padding: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Header-Containeer .Image-Placeholder .Header-Overlay h3{
    color: white;
    letter-spacing: 7px;
    text-transform: uppercase;
    font-weight: 400;
    margin-bottom: 10px;

}


.Header-Containeer .Image-Placeholder .Header-Overlay h1{
    color: white;
    font-size: 42px;
    font-weight: 700;
}

@media all and (max-width:500px) {
    .Header-Containeer .Image-Placeholder{
        width: 100% !important;
    }
    .Header-Containeer{
        margin-top: 60px !important;
    }
}