.latest-container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
}

.latest-container .sub_container{
    background-color: #D9D9D9;
    width: 90%;
    padding: 20px;
    margin-bottom:100px 
}


/* img {
    height: 200px;
    width: 300px;
    border-radius: 3px;
    transition: all 0.3s ease 0s;
}
.intro{
    width: 300px;
    height: 60px;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(27, 27, 27,.5);
    color: white;
    bottom: 50px;
    overflow: hidden;
    transition: all 0.5s ease 0s;
}
.intro h1{
    font-size: 24px;
    margin:10px ;
}
.intro p{
    font-size: 16px;
    margin: 10px;
    visibility: hidden;
}

.card{
    height: 200px;
    width: 300px;
    margin: 50px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
}
.card:hover{
    cursor: pointer;
}

.card:hover .intro{
    height: 200px;
    background-color: rgba(27, 27, 27,.8);
    
}
.card:hover p{
    visibility: visible;
}

.card:hover img{
    transform: scale(1.1) rotate(-3deg);
} */