.footer-containeer{
    width: 100%;
    background-color: #373A43;
    display: flex;
    justify-content: center;
    align-items: end;
}

.social-links{
    display: flex;
}
.footer-containeer .sub-containeer{
    margin-top: 50px;
    margin-bottom: 50px;
    width: 80%;
    border-top: solid 2px #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.footer-containeer .sub-containeer .row{
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
}


.footer-containeer .sub-containeer .row ul{
	list-style: none;
}
.footer-containeer .sub-containeer .row .footer-col{
    width: 25%;
    padding: 0 15px;
 }
.footer-containeer .sub-containeer .row  .footer-col h4{
	font-size: 18px;
	color: #ffffff;
	text-transform: capitalize;
	margin-bottom: 35px;
	font-weight: 500;
	position: relative;
}
.footer-containeer .sub-containeer .row .footer-col h4::before{
	content: '';
	position: absolute;
	left:0;
	bottom: -10px;
	background-color: #D1AB64;
	height: 2px;
	box-sizing: border-box;
	width: 50px;
}
.footer-containeer .sub-containeer .row .footer-col ul li:not(:last-child){
	margin-bottom: 10px;
}
.footer-containeer .sub-containeer .row .footer-col ul li a{
	font-size: 16px;
	text-transform: capitalize;
	color: #ffffff;
	text-decoration: none;
	font-weight: 300;
	color: #bbbbbb;
	display: block;
	transition: all 0.3s ease;
}
.footer-containeer .sub-containeer .row .footer-col ul li a:hover{
	color: #ffffff;
	padding-left: 8px;
}
/* .footer-containeer .sub-containeer .row .footer-col .social-links a{
	display: inline-block;
	height: 40px;
	width: 40px;
	background-color: rgba(255,255,255,0.2);
	margin:0 10px 10px 0;
	text-align: center;
	line-height: 40px;
	border-radius: 50%;
	color: #ffffff;
	transition: all 0.5s ease;
}
.footer-containeer .sub-containeer .row .footer-col .social-links a:hover{
	color: #24262b;
	background-color: #ffffff;
} */
.footer-containeer .sub-containeer h1{
    font-weight: 600;
    font-size: 26px;
    color: white;
}
.footer-containeer .sub-containeer .social-containeer{
    display: flex;
}

.icon{
    display: block;
    width: 50px;
    height: auto;
    cursor: pointer;
}

.icon-color{
    fill: white;
    transition: all 0.3s ease 0s;
}
.icon:hover .facebook{
    fill:#4267B2
}
.icon:hover .linked{
    fill: #0A66C2
}

.icon:hover .youtube{
    fill: red
}

/*responsive*/
@media(max-width: 1100px){
    .footer-containeer .sub-containeer .row  .footer-col{
      width: 33%;
      margin-bottom: 30px;
  }
  }
@media(max-width: 840px){
    .footer-containeer .sub-containeer .row  .footer-col{
      width: 50%;
      margin-bottom: 30px;
  }
  }
  @media(max-width: 574px){
    .footer-containeer .sub-containeer .row .footer-col{
      width: 100%;
  }
  }