.card img {
    height: 200px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s ease 0s;
}
.card .intro{
    width: 100%;
    height: 70px;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(27, 27, 27,.5);
    color: white;
    bottom: 0px;
    overflow: hidden;
    transition: all 0.5s ease 0s;
}
.card .intro h1{
    color: white;
    font-size: 24px;
    margin:10px ;
    margin-bottom: 5px;
    transition: all 0.5s ease 0s;
}
.card .intro p{
    font-size: 16px;
    margin: 10px;
    visibility: hidden;
}

.card{
    position: relative;
    height: 200px;
    width: 300px;
    margin: 25px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
}
.card .intro .column-btn{
    height: 0px;
    width: 40%;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 22px;
    text-align: center;
    border-radius: 5px;
    padding: 0px;
    color: #fff;
    font-size: 18px;
    border: solid 0px #fff;
    opacity: 1;
}
.card:hover{
    cursor: pointer;
}

.card:hover .intro{
    height: 200px;
    background-color: rgba(27, 27, 27,.8);
    
}
.card:hover .column-btn{
    height: auto;
    padding: 8px;
    border-width: 1px;
}

.card:hover .card .intro h1{
    margin-bottom: 10px;
}

.card:hover img{
    transform: scale(1.1) rotate(-3deg);
}