.image-card img {
    height: 200px;
    width: 100%;
    border-radius: 3px;
    transition: all 0.3s ease 0s;
}
.image-card .intro{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    box-sizing: border-box;
    position: absolute;
    background-color: rgba(27, 27, 27,0);
    color: white;
    bottom: 0px;
    overflow: hidden;
    transition: all 0.5s ease 0s;
}

.image-card{
    position: relative;
    height: 200px;
    width: 300px;
    margin: 25px;
    box-shadow: 5px 5px 20px black;
    overflow: hidden;
}
.image-card .intro .column-btn{
    width: 40%;
    overflow: hidden;
    margin-left: 10px;
    margin-top: 22px;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    font-size: 18px;
    border: solid 1px #fff;
    opacity: 0;
    transition: all 0.5s ease 0s;

}
.image-card:hover .column-btn{
    opacity: 1;
}

.image-card:hover{
    cursor: pointer;
}

.image-card:hover .intro{
    background-color: rgba(27, 27, 27,.8);
    
}



.image-card:hover img{
    transform: scale(1.1) rotate(-3deg);
}