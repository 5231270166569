.team{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.team .containeer{
    width: 80%;
    
}

.team .containeer h1{
    color: #D1AB64;
    margin-bottom: 20px;
    font-weight: 600;
}
.team .containeer h3{
    margin-bottom: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}