.hero-container{
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px 0px 0px 50px;
    margin-top: 70px;
}
.word-container{
    width: 100%;
    border-left-style: solid;
    border-left-color: white;
    padding-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.word-container h3{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 70px;
    color: white;
}

.word-container p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    font-size: 32px;
    line-height: 39px;

    color: #FFFFFF;
}

.word-container p span{

    font-weight: 500;
    font-size: 32px;
}

@media all and (max-width:620px) {
    .hero-container{
        padding: 20px!important;
    }
    .word-container{
        padding: 20px!important;
    }
    .word-container p{
        font-size: 25px!important;
    }
    .word-container h3{
        font-size: 42px!important;
    }
}

