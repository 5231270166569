.image-card2 img {
    height: 250px;
    width: 250px;
    border-radius: 3px;
    transition: all 0.3s ease 0s;
}


.image-card2{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.image-card2 .H1{
    text-align: center;
    font-size: 26px;
    color: #4c4c4c!important;
    font-weight: 400!important;
    margin: 0px;
    padding: 0px;
    margin-top: 10px;
}

