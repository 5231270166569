.Description-Containeer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
}
.Description-Containeer p{
    width: 80%;
    color: #4c4c4c;
    line-height: 24px;
    margin-bottom: 24px;
}

@media all and (max-width:500px) {
    .Description-Containeer p{
        width: 90%;
    }
}