.pop-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    width: 100%;
    height: 100vh;
    background-color: #1B1D1E;
    top: 0;
    left: 0;
    z-index: 20000;
    overflow: hidden;
}
.pop-container p{
    font-size: large;
    color: white;
}
.pop-container .close-div{
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: right;
    align-items: center;
}
.pop-container .close-div .close-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 50px;
    height: 50px;
    background-color: black;
    border-radius: 50%;
    margin: 10px;
    cursor: pointer;
    z-index: 5;
}
.pop-container .close-div .close-btn p{
    color: white;
    font-size: 26px;
    font-weight: 600;
}
.pop-container .image-slider{
    width: 100%;
    height: 90vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.pop-container .image-slider img{
    width: 100%;
}