.services-container{
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 50px;
}

.services-container h1{
    color: #D1AB64;
    margin-bottom: 20px;
    font-weight: 600;
}
.services-container h2{
    margin-bottom: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}
.services-container .sub_container{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.services-container .sub_container .services-columns{
    position: relative;
    width: 80%;
    height: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;}


.services-container .sub_container .services-columns .column{
    width: 18%;
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0,0,0,0.35);
    z-index: 4;
    transition: all 0.5s ease 0s;
}
.services-container .sub_container .services-columns .main-column{
    width: 28%;
}

.services-container .sub_container .services-columns .column-border{
    border-right: solid 1px #fff;
}



.services-container .sub_container .services-columns .column h1{
    color: white;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
}
.services-container .absolute-color{
    background-color: #373A43;
    height: 200px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
}

.img1{
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.column-btn{
    margin-top: 20px;
    text-align: center;
    border-radius: 5px;
    padding: 8px;
    color: #fff;
    font-size: 18px;
    border: solid 1px #fff;
    opacity: 0;
    transition: all 0.5s ease 0s;
}

.services-container .sub_container .services-columns .column:hover + .img1{
    opacity: 1;
}

.services-container .sub_container .services-columns .column:hover{
    background-color: rgba(0,0,0,0.7);
    cursor: pointer;
}

.column:hover .column-btn{
    opacity: 1;
}


@media all and (max-width:1100px) {
    .services-container .sub_container .services-columns{
        width: 100% !important;
    }
}
@media all and (max-width:850px) {
    .services-container{
        padding: 30px !important;
    }
}

@media all and (max-width:770px) {
    .services-container .sub_container .services-columns .column{
        width: 100% !important;
        height: 20% !important;
    }
    .services-container .sub_container .services-columns .column-border{
        border-right: solid 0px #fff !important;
        border-bottom: solid 1px #fff !important;
    }
    .column-btn{
        display: none !important;
    }
}