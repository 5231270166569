.chairman{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.chairman .containeer{
    width: 80%;
    
}

.chairman .containeer h1{
    color: #D1AB64;
    margin-bottom: 20px;
    font-weight: 600;
}
.chairman .containeer h3{
    margin-bottom: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}
.chairman-grid{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}
.image-half{
    width: 25%;
    height: 350px;
    padding: 5%;
}
.dr-image{
    width: 100%;
    height: 100%;
}

.article-half{
    width: 50%;
    padding: 2%;
}
.article-half p{
    color: #4c4c4c;
    margin-bottom: 15px;
}





@media all and (max-width:1300px) {
    .chairman .containeer{
        width: 100% !important;
    }
    .chairman .containeer h1,.chairman .containeer h3{
        margin-left: 20px !important;
    }
    .chairman .article-half .special{
        margin-left: 0px !important;
    }

}

@media all and (max-width:920px) {
    .article-half{
        width: 100% !important;
    }
    .image-half{
        width: 40% !important;
        height: 407px !important;
    }
}

@media all and (max-width:750px) {
    .image-half{
        width: 40% !important;
        height: 312px !important;
    }
}

@media all and (max-width:650px) {
    .image-half{
        height: 270px !important;
    }
}

@media all and (max-width:450px) {
    .image-half{
        height: 210px !important;
    }
}


