.about-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 50px;
}
.text-container{
    padding: 40px;
    width: 50%;
    height: 100%;
}
.text-container h1{
    margin-bottom: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    color: #000000;
}
.text-container p{
    font-style: normal;
    font-weight: 300;
    font-size: 26px;
    line-height: 70px;

    color: #000000;
}

.text-container p span{
    font-weight: 500;
    color: #D1AB64;
}

.image-container{
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-stack{
    display: grid;
    grid-template-columns: repeat(12,1fr);
}

.image-stack__item--top{
    grid-column: 5/span 12;
    grid-row: 1;
    z-index: 0;
    transition: all 0.3s ease 0s;
}


.image-stack__item--bottom{
    grid-column: 4/span 8;
    grid-row: 1;
    z-index: 0;
    padding-top: 7%;

}

.image-stack__item img{
    width: 70%;
    height: auto;
}

@media all and (max-width:1100px) {
    .image-stack__item img{
        width: 100%!important;
    }
}

@media all and (max-width:900px) {
    .image-container{
        display: none;
    }
    
    .text-container{
        width: 100%!important;
        padding: 0px!important;
    }
}

@media all and (max-width:500px) {
    .about-container{
        padding: 30px!important;
        margin-bottom: 20px !important;
    }
    .text-container p{
        font-size: 25px !important;
        line-height: 40px !important;
    }
}