/* General styles*/


li, a, button{
    text-decoration: none;
}

nav{
    top: 0;
    right: 0;
    width: 100%;
    position: fixed;
    font-size: 12px;
    color: white;
    background-color: rgba(0, 0, 0, 1);
    padding: 5px 50px;
    z-index: 10001;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5), 0 6px 20px 0 rgba(0, 0, 0, 0.2);
}

.nav__links{
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.logo{
    width: 120px;
    cursor: pointer;
}

.nav__links li{
    display: inline-block;
    padding: 0px 20px;
    margin-bottom: 5px;
}

.nav__links li a{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: white;
    transition: all 0.3s ease 0s;
}
.nav__links li a.custom-active{
    color: #D1AB64;
    font-weight: 600;
    border-bottom: solid 4px #D1AB64;
    padding-bottom: 10px;
    transition: all 0.3s ease 0s;
}

.nav__links li a:hover{
    color: #D1AB64;
}

nav button{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 12px 24px;

    width: 150px;
    height: 40px;

    background: #D1AB64;
    border: 2px solid #D1AB64;
    border-radius: 8px;

    
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    text-align: center;


    color: #FFFFFF;

    cursor: pointer;
    transition: all 0.3s ease 0s;
}

button:hover{
    background-color: #FFFFFF;
    color: #D1AB64;
}
.contact-mobile{
    display: none;
}

/* Navbar Toggle */
.toggle {
    cursor:pointer;
  }
  .bars {
    background: #999;
    display: inline-block;
    height: 3px;
    position: relative;
    transition: background .2s ease-out;
    width: 25px;
  }
  .bars:before,
  .bars:after {
    background: #999;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }
  .bars:before {
    top: 7px;
  }
  .bars:after {
    top: -7px;
  }
  .toggle{
    display: none!important;
  }

  /*Mobile*/
@media all and (max-width:880px) {
    nav{
        padding: 5px 15px;
    }
    .toggle {
        display: inline-block!important;
        order: 1;
    }
    .item {
        width: 100%;
        text-align: center;
        order: 3;
        display: none!important;
        margin-bottom: 15px!important;

    }
    .item.active-display {
        display: block!important;
    }
    nav button{
        display: none!important;
    }
    .contact-mobile{
        display: inline-block!important;
    }
    .nav__links li a.custom-active{
        border: none;
    }

}
