.related-works{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
}

.related-works .containeer{
    width: 80%;
    
}

.related-works .containeer h1{
    color: #D1AB64;
    margin-bottom: 20px;
    font-weight: 600;
}
.related-works .containeer h3{
    margin-bottom: 10px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 6px;
}

.related-works .containeer .works-grid{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

@media all and (max-width:450px) {
    .related-works .containeer{
        width: 100%;
    }
}